<template>
  <div class="teaching-container">
    <!-- <div class="container-head">
      <div class="head-logo">
        <img alt="logo" src="~@/assets/image/logo-new.png" />
      </div>
      <div style="position: absolute;right:2%;top:2%">
        <el-dropdown>
          <span class="el-dropdown-link">
            <el-avatar :size="40">用户</el-avatar>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><el-link @click="leaveConfirm">退出</el-link></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div> -->
    <div
      class="container-content" style="margin-left:0"
      :class="collapse ? '' : 'container-content-without-sider'"
    >
      <div class="content-main">
        <iframe
          :src="iframeSrc"
          style="width: 100%; height: 100%; background: #fff"
          id="coding"
        />
      </div>
      <div
        v-viewer="imgViewOptions"
        class="images clearfix"
        style="display: none"
      >
        <img :src="imgSrc" :data-source="imgSrc" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseGuide, getCourseResource,getCourseDetail } from '@/api/course';
import TeachingGuideCard from '@/components/teaching/TeachingGuideCard.vue';
import TeachingDataCard from '@/components/teaching/TeachingDataCard.vue';
import PracticeQuestionList from '@/components/teaching/PracticeQuestionList.vue';
import Empty from '@/components/modules/Empty.vue';
import Vue from 'vue';
import { ACCESS_TOKEN,GO_BACK_URL } from '@/store/mutation-types';
import { Message } from 'element-ui';
// const mfxUrlDev = 'https://www-stage.mofaxiao.com/stu/choiceOrg';
// const mfxUrl = 'https://www.mofaxiao.com';
const STUDENT = 1;
const TEACHER = 2;
export default {
  data() {
    return {
      goBackUrl:null,
      lab:null,
      activeTab: 'guide',
      userId:null,
      lessonId:null,
      iframeSrc: '',
      unitId: '',
      unitName: '',
      collapse: true,
      totalStep: 0,
      stepIndex: 1,
      guideList: [],
      imgSrc: 'http://localhost:8080/img/match-bg.81a37cf9.png',
      imgViewOptions: {
        toolbar: true,
        url: 'data-source',
      },
      resourceList: [],
      hasGetData: {data: false,practice: false} //是否请求数据
    };
  },
  components: {
    TeachingGuideCard,
    TeachingDataCard,
    PracticeQuestionList,
    Empty,
  },
  created: function () {
    this.userId = this.$route.query.user_id;
    this.lessonId = this.$route.query.lesson_id;
    const schoolId = this.$route.query.school_id;
    const userToken = this.$route.query.user_token;
    const auth = this.$route.query.auth;
    const liveOrgLessonId = this.$route.query.live_org_lesson_id;
    const lessonStartAt = this.$route.query.lesson_start_at;
    const lessonEndAt = this.$route.query.lesson_end_at;
    const routerTo = this.$route.path;
    if(!this.lessonId ||!auth || !this.userId){
          this.$notify({
            title: '系统提示',
            message: '资源未找到！',
            type: 'warning'
        });
    }
    const token = Vue.ls.get(ACCESS_TOKEN);
    if (!token) {
      location = `/courseTransfer?routerTo=${routerTo}&user_id=${this.userId}&user_token=${userToken}&school_id=${schoolId}&lesson_id=${this.lessonId}&auth=${auth}&live_org_lesson_id=${liveOrgLessonId}&lesson_start_at=${lessonStartAt}&lesson_end_at=${lessonEndAt}`
    }
    this.goBackUrl = Vue.ls.get(GO_BACK_URL);
    let param = {
      auth:auth,
    }
    this.handleLessionDetail(param);
  },
  mounted(){
  },
  updated: function () {
    const imgEles = document.querySelectorAll('.teaching-guide-card .text img');
    for (let index = 0; index < imgEles.length; index++) {
      const element = imgEles[index];
      element.style.cursor = 'pointer';
      element.style.width = 'auto';
      element.style.height = 'auto';
      element.style.maxWidth = '100%';
      element.style.maxHeight = '100%';
      element.title = '点击放大查看';
      element.addEventListener('click', (e) => {
        this.imgSrc = e.target.src;
        this.show();
      });
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
     handleLessionDetail(param){
      let _this = this;
      getCourseDetail({lesson_id:this.lessonId}).then( res => {
          if(res.code == 200){
              const {studyLink,unitName} = res.result;
              let auth = param.auth;
              _this.unitName = unitName;
              if(studyLink){
                  const links = studyLink.split("?");
                  if(links && links.length > 0){
                      let unitIdArray = "";
                      let labString = links[0];
                      _this.lab = labString.substring(1,labString.length);
                      unitIdArray = links[1];
                      _this.unitId = unitIdArray.split("=")[1];
                  }
              }
              let examAnswerId = '';
              if(auth == TEACHER){
                examAnswerId = 'mfx';
              }
              let route = ``;
              if (_this.lab === 'scratch3') {
                route = `/${_this.lab}?unitId=${_this.unitId}&examAnswerId=${examAnswerId}&auth=${auth}&user_id=${_this.userId}`;
              } else {
                route = `/coding?lab=${_this.lab}?unitId=${_this.unitId}&examAnswerId=${examAnswerId}&auth=${auth}&user_id=${_this.userId}`;
              }
              _this.iframeSrc =
                window.location.host.indexOf('localhost') === -1
                  ? `${window.location.protocol}//${window.location.host}${route}`
                  : `https://www.eqibian.com${route}`;
              const params = { courseUnitId: _this.unitId };
              getCourseGuide(params)
                .then((res2) => {
                  //获取失败
                  if(!res2.success){
                    _this.$message.error(res2.message || "获取课程数据失败");
                    return;
                  }
                    const { result = [] } = res2;
                    _this.guideList = result;
                    _this.totalStep = (result && result.length) || 0;
                })
                .catch((err) => {});
          }else{
            _this.$message.error("获取课程信息失败");
          }
      })
  },
    addClassWithImg(htmlContent) {
      let r = RegExp(/<[img]+\s+(.*?)(?<id>\w*?)[\s'"](.*?)>/g);
      let matchres = htmlContent.match(r); //返回数组['<img ... />','<img ... />']
      if (matchres) {
        matchres.forEach((item, index) => {
          let len = item.length;
          let _str =
            item.slice(0, len - 2) +
            ' style="width:60%;height:200px;text-align:center;margin:0 20%;"/>'; //追加之后的img
          htmlContent = htmlContent.replace(item, _str);
        });
      }
      const resultHtml = parseHtml(htmlContent);
      return resultHtml;
    },
    show() {
      const viewer = this.$el.querySelector('.images').$viewer;
      viewer.show();
    },
    onImgClick(e) {},
    handleNextStepClick() {
      this.handleStepClick(this.stepIndex + 1);
    },
    handleStepClick(step) {
      this.activeTab = 'guide';
      this.stepIndex = step;
      const scrollEle = document.getElementsByClassName('el-tabs__content')[0];
      const guideList = document.getElementById('pane-guide').children;
      scrollEle.scrollTop = 0; //guideList[this.stepIndex - 1].offsetTop;
    },
    handleTabClick(tab, event) {
      if (tab.name === 'data'&&!this.hasGetData['data']) {
       getCourseResource({ courseUnitId: this.unitId }).then((res) => {
          //获取失败
          if(!res.success){
            this.$message.error(res.message || "获取课程数据失败");
            return;
          }
          const result = res.result || [];
          this.resourceList = result;
          this.hasGetData[data] = true;
        });
      } else if (tab.name === 'practice'&&!this.hasGetData['practice']) {
        this.hasGetData['practice'] = true;
      }
    },
    handleCollapseChage() {
      this.collapse = !this.collapse;
    },
    handleSubmitClick() {},
    /**
     * 离开课堂按钮
     */
    leaveConfirm(){
      let _this = this;
      this.$confirm('确定要退出吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.closeWindows();
      }).catch(() => {});
    },
    /**
     * 跳回魔法校
     */
    closeWindows() {
       location.href = this.goBackUrl;
    }
  }
};
</script>
<style scoped>
.sider-content /deep/.el-tabs__item {
  padding: 0 12px;
  font-weight: bold;
}
.sider-content /deep/ .el-tabs__item:hover {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__item.is-active {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__active-bar {
  background-color: #409eff;
  height: 4px;
}
.sider-content /deep/ .el-tabs {
  height: 100%;
}
/* .sider-content /deep/ .el-tabs{
        overflow: auto;
        height: calc(100% - 57px);
    } */
.head-logo{
  position: absolute;
  left: 18px;
}
.head-logo img{
  width: 100px;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/teaching.scss';
.container-content{
  height: 100% !important;
}
#coding{
  margin-left: 0 !important;
}
</style>
